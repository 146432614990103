import { AppShell } from "@mantine/core";
import React, { useState } from "react";
import { DashboardPage } from "../Components/account/DashboardPage";
import { AppHeader } from "../Components/app-header/AppHeader";
import { AppSideBar } from "../Components/app-side-bar/AppSideBar";
import { useAuth } from "../Context/useAuth";
import { useDisclosure } from '@mantine/hooks';

function Dashboard(): JSX.Element {
  const { user } = useAuth();
  const [currentConsentData, setCurrentConsentData] = useState<any | null>(null);
  const [opened, { toggle }] = useDisclosure();
  const [refresh, setRefresh] = useState(false); // NEW: Refresh state

  const handleActiveConsentData = (consentData:any) => {
    setCurrentConsentData(consentData);
  };

  const handleDomainAdded = () => {
    setRefresh((prev) => !prev); // Toggle state to refresh dropdown
  };

  return (
    <React.Fragment>
      <>
        <AppShell
            padding={user && !user?.newUser ? 'md' : 0}
            style={{ backgroundColor: user ? (user?.newUser ? '#f5f5f5' : '#f2f4fa') : '#f2f4fa' }} // Default color if user is null
            header={{ height: 60 }}
            navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          >
          <AppShell.Header>
            <AppHeader opened={opened} toggle={toggle} user={user} activeConsentData={handleActiveConsentData} refresh={refresh} />
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSideBar name="Home" />
          </AppShell.Navbar>
          <AppShell.Main>
                <DashboardPage user={user} currentConsentData={currentConsentData} onDomainAdded={handleDomainAdded} />
          </AppShell.Main>
        </AppShell>
      </>
    </React.Fragment>
  );
}

export { Dashboard };
