import { Button, Center, Stack, Text, ThemeIcon, Title } from "@mantine/core";
import { IconConfetti } from "@tabler/icons-react";

function Completion(props:any) {

    return (
      <Stack p="xl" align="center">
        <Title order={2} fw={500}>You are all set!</Title>
        <Center>
            <ThemeIcon radius={100} size={90} color="rgba(224, 224, 224, 1)">
                <IconConfetti color="blue" size={40} />
            </ThemeIcon>  
        </Center>
        <Text size="xs">Now, scan your domain to identify and categorize all active cookies, ensuring accurate and compliant cookie consent for your users.</Text>
  
        <Button variant="default" fullWidth  onClick={() => { props.close(); props.handleRefresh(props.domainId); }}>
          Finish
        </Button>
      </Stack>
    );
  }

  export { Completion };