import { useState } from "react";
import { Text } from "@mantine/core";
import {
  IconHome,
  IconPolaroid,
  IconSettings,
  IconReportAnalytics,
  IconHeadset,
  IconWorld,
  IconReport,
  IconMoneybag,
  IconPower,
} from "@tabler/icons-react";
import classes from "./sidebar.module.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/useAuth";

const data = [
  { link: "/dashboard", label: "Home", icon: IconHome },
  { link: "/domains", label: "Domains", icon: IconWorld },
  { link: "/privacy-audits", label: "Privacy Audits", icon: IconReport },
  { link: "/consentBanners", label: "Consent Banner", icon: IconPolaroid },
  { link: "/integration", label: "Integration", icon: IconSettings },
  { link: "/analytics", label: "Analytics", icon: IconReportAnalytics },
  { link: "/profile", label: "Billing And Subscriptions", icon: IconMoneybag },
  { link: "", label: "Help And Support", icon: IconHeadset },
  { link: "logout", label: "Logout", icon: IconPower },
];

export function AppSideBar(props: any) {
  const [active, setActive] = useState(props.name);
  const { logout } = useAuth();

  var links = data.map((item) =>
    item.label === "Logout" ? (
      <button
        key={item.label}
        className={classes.link}
        onClick={() => logout()}
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <item.icon className={classes.linkIcon} stroke={1.3} />
        <Text size="xs" fw={600} c="black">
          {item.label}
        </Text>
      </button>
    ) : (
      <Link
        className={classes.link}
        data-active={item.label === active || undefined}
        to={item.link}
        key={item.label}
        onClick={() => setActive(item.label)}
      >
        <item.icon className={classes.linkIcon} stroke={1.3} />
        <Text size="xs" fw={600} c="black">
          {item.label}
        </Text>
      </Link>
    )
  );

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>{links}</div>
    </nav>
  );
}
