import { Button, Group, Paper, Radio, Select, SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { useState } from "react";
import { ConsentConfig } from "../../../Models/consentConfig";
import { defaultBannerContent, finalConsentConfig, themesConfig } from "../../../utils/utils";

function ConsentConfiguration(props:any) {
    const [consentExpiry, setConsentExpiry] = useState<any>('6 months');
    const [bannerLayout, setBannerLayout] = useState<any>('Box');
    const [bannerPosition, setBannerPosition] = useState<any>('leftBottomPopUp');
    const [consentConfig ] = useState<ConsentConfig>(finalConsentConfig);

    const handleConsentExpire = (expiry:any) => {
        props.handleExpire(expiry, "consentExpire");
        setConsentExpiry(expiry);
    };
    
    const saveConsentBannerConfig = () => {
        props.handleExpire(consentExpiry, "consentExpire");
        consentConfig.layout = bannerLayout;
        consentConfig.alignment = bannerPosition;
        consentConfig.theme = themesConfig[0];
        consentConfig.type = "Light";
        consentConfig.bannerContent = defaultBannerContent;
        props.consentData(consentConfig);
    };

    return (
      <div>
        <Title ta="center" order={2} mt="lg" fw={500}>Consent Configuration</Title>
        <Stack  gap={10} p="lg" pl="xl" pr="xl">
            <Title order={6} fw={500}>Consent expiry</Title>
            <Select
                value={consentExpiry}
                onChange={handleConsentExpire}
                data={['3 months', '6 months', '1 year']}
            />
        
            <Title order={6} fw={500}>Consent Banner Layout</Title>
            <SimpleGrid cols={3}>
            <div>
            <Paper shadow="0px" withBorder
                h="70"
                w="90" style={{ position: "relative" }}
                onClick={(event) => {
                    setBannerLayout("Box");
                    setBannerPosition("leftBottomPopUp");
                }}
                >
                <div
                    className="fill-primary"
                    style={{
                    border: "1px solid #eaeaea",
                    left: "7px",
                    bottom: "7px",
                    padding: "10px",
                    position: "absolute",
                    width: "40%"
                    }}
                ></div>
                {bannerLayout == "Box" && (
                    <IconCircleCheckFilled
                    className="primary"
                    size="1rem"
                    stroke={3}
                    style={{ left: "-8px", top: "-7px", position: "absolute" }}
                    />
                )}
                </Paper>
                <Text size="xs" mt={5}>
                Box
                </Text>
            </div>
            <div>
                <Paper
                shadow="0px"
                withBorder
                p="lg"
                h="70"
                w="90"
                style={{ position: "relative" }}
                onClick={(event) => {
                    setBannerLayout("Banner");
                    setBannerPosition("bannerBottom");
                }}
                >
                <div
                    className="fill-primary"
                    style={{
                    border: "1px solid #eaeaea",
                    left: "5px",
                    bottom: "7px",
                    padding: "5px",
                    position: "absolute",
                    width: "80%",
                    }}
                ></div>
                {bannerLayout == "Banner" && (
                    <IconCircleCheckFilled
                    className="primary"
                    size="1rem"
                    stroke={3}
                    style={{ left: "-8px", top: "-7px", position: "absolute" }}
                    />
                )}
                </Paper>
                <Text size="xs" mt={5}>
                Banner
                </Text>
            </div>
            <div>
                <Paper
                shadow="0px"
                withBorder
                p="lg"
                h="70"
                w="90"
                style={{ position: "relative" }}
                onClick={(event) => {
                    setBannerLayout("PopUp");
                    setBannerPosition("popUpCenter");
                }}
                >
                <div
                    className="fill-primary"
                    style={{
                    border: "1px solid #eaeaea",
                    left: "30%",
                    bottom: "30%",
                    padding: "10px",
                    position: "absolute",
                    width: "40%"
                    }}
                ></div>
                {bannerLayout == "PopUp" && (
                    <IconCircleCheckFilled
                    className="primary"
                    size="1rem"
                    stroke={3}
                    style={{ left: "-8px", top: "-7px", position: "absolute" }}
                    />
                )}
                </Paper>
                <Text size="xs" mt={5}>
                    Popup
                </Text>
            </div>
            </SimpleGrid>
            {bannerLayout == "Box" && (
            <Radio.Group mt={15} mb={15} value={bannerPosition} onChange={setBannerPosition}>
                <SimpleGrid cols={2}>
                <Radio size="sm" value="leftBottomPopUp" label="Bottom Left" />
                <Radio size="sm" value="rightBottomPopUp" label="Bottom Right" />
                <Radio size="sm" value="leftTopPopUp" label="Top Left" />
                <Radio size="sm" value="rightTopPopUp" label="Top Right" />
                </SimpleGrid>
            </Radio.Group>
            )}
            
            <Group justify="apart" grow>
                <Button variant="default" onClick={props.prevStep}>Back</Button>
                <Button onClick={(event) => {
                    saveConsentBannerConfig();
                  }}
                > Next</Button>
            </Group>
        </Stack>
      </div>
    );
  }

  export { ConsentConfiguration };