import { AppShell } from "@mantine/core";
import React, { useState } from "react";
import { AppHeader } from "../Components/app-header/AppHeader";
import { AppSideBar } from "../Components/app-side-bar/AppSideBar";
import { useAuth } from "../Context/useAuth";
import { useDisclosure } from '@mantine/hooks';
import { PricingScreen } from "../Components/account/PricingScreen";

function Pricing(): JSX.Element {
  const { user } = useAuth();
  const [currentConsentData, setCurrentConsentData] = useState<any | null>(null);
  const [opened, { toggle }] = useDisclosure();

  const handleActiveConsentData = (consentData:any) => {
    setCurrentConsentData(consentData);
  };

  return (
    <React.Fragment>
      <>
        <AppShell
          padding="md"
          style={{ background: "#fff" }}
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
        >
          <AppShell.Header>
            <AppHeader opened={opened} toggle={toggle} user={user} activeConsentData={handleActiveConsentData} />
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSideBar name="Billing And Subscriptions" />
          </AppShell.Navbar>
          <AppShell.Main>
            <PricingScreen key={Math.random()}  user={user} currentConsentData={currentConsentData} />
          </AppShell.Main>
        </AppShell>
      </>
    </React.Fragment>
  );
}

export { Pricing };
