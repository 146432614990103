import axios from "axios";
import { UserProfileToken } from "../Models/User";
import { Config } from "../utils/types";

export class AuthService {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  public async login(email: string, password: string) {
    try {
      const data = await axios.post<UserProfileToken>(this.config.apiBaseUrl + "/auth/login", {
        email: email,
        password: password,
      });
      return data;
    } catch (error) {
      return error;
    }
  };

  public async register(email: string, domainUrl: string, password: string) {
    try {
      const data = await axios.post<UserProfileToken>(
        this.config.apiBaseUrl + "/auth/signup", {
          email: email,
          domainUrl: domainUrl,
          name:null,
          password: password
        }
      );
  
      return data;
    } catch (error) {
      return error;
    }
  }

  public async connect(email: string, password: string, mode:string, token:string) {
    try {
      const data = await axios.post<UserProfileToken>(
        this.config.apiBaseUrl + "/auth/user/domain/" + mode, {
          email: email,
          password: password
        },
        {headers: 
          {
            Authorization: `Bearer ${token}`,
          }
        }
      );
  
      return data;
    } catch (error) {
      return error;
    }
  }

  public async resendEmail(email: string, otp_type: string) {
    try {
      const data = await axios.put<UserProfileToken>(`${this.config.apiBaseUrl}/auth/${otp_type}/resend`, {
        email: email
      });

      return data;
    } catch (error) {
      return error;
    }
  };

  public async verifyEmail(code: string) {
    try {
      const data = await axios.put<UserProfileToken>(this.config.apiBaseUrl + "/auth/verify", {
        code: code
      });

      return data;
    } catch (error) {
      return error;
    }
  }

  public async verifyCode(code: string) {
    try {
      const data = await axios.put<UserProfileToken>(this.config.apiBaseUrl + "/auth/validate", {
        code: code
      });

      return data;
    } catch (error) {
      return error;
    }
  }

  public async resetPassword(email: string) {
    try {
      const data = await axios.put<UserProfileToken>(this.config.apiBaseUrl + "/auth/resetPassword", {
        email: email
      });

      return data;
    } catch (error) {
      return error;
    }
  }

  public async updatePassword(password: string, confirmPassword: string, encryptedCode:string) {
    try {
      const data = await axios.put<UserProfileToken>(this.config.apiBaseUrl + "/auth/recoverPassword", {
        password: password,
        confirmPassword:confirmPassword,
        code:encryptedCode
      });

      return data;
    } catch (error) {
      return error;
    }
  }
}
