import { AppShell } from "@mantine/core";
import React, { useState } from "react";
import { DomainPage } from "../Components/account/DomainPage";
import { AppHeader } from "../Components/app-header/AppHeader";
import { AppSideBar } from "../Components/app-side-bar/AppSideBar";
import { useAuth } from "../Context/useAuth";
import { useDisclosure } from '@mantine/hooks';

function Domains(): JSX.Element {
  const { user } = useAuth();
  const [opened, { toggle }] = useDisclosure();
  const [refresh, setRefresh] = useState(false); // NEW: Refresh state

  const handleDomainAdded = () => {
    setRefresh((prev) => !prev); // Toggle state to refresh dropdown
  };

  return (
    <React.Fragment>
      <>
        <AppShell
          padding="md"
          style={{ background: "#f2f4fa" }}
          header={{ height: 60 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
        >
          <AppShell.Header>
            <AppHeader user={user} refresh={refresh} />
          </AppShell.Header>
          <AppShell.Navbar>
            <AppSideBar opened={opened} toggle={toggle} name="Domains" />
          </AppShell.Navbar>
          <AppShell.Main>
            <DomainPage  onDomainAdded={handleDomainAdded} />
          </AppShell.Main>
        </AppShell>
      </>
    </React.Fragment>
  );
}

export { Domains };
