import {
  Paper,
  Text,
  Container,
  Space,
  Title,
  Group,
  Card,
  rem,
  SimpleGrid,
  Button,
  Center,
  LoadingOverlay,
  Grid,
  Popover,
  Radio,
  Stack,
  ActionIcon,
  Box
} from "@mantine/core";
import {
  IconCalendar,
  IconChartPie,
  IconClockRecord,
  IconDoorEnter,
  IconDoorExit,
  IconFilter,
  IconDownload,
  IconX,
} from "@tabler/icons-react";
import React, { useState, useEffect, useContext, useRef  } from "react";
import { DatePickerInput } from "@mantine/dates";
import axios from "axios";
import { useForm } from "@mantine/form";
import { ConfigContext } from "../../Context/configContext";
import { useTitle } from "../hooks/useTitle";
import { getAuthorizationHeader } from "../../utils/getAuthorizationHeader";
import AnalyticsChart from "./AnalyticsChart";

interface AnalyticsResponse {
  totalOptIns: number;
  totalOptOuts: number;
  averageTimeToConsent: number;
  mostAcceptedCookie: string;
  leastAcceptedCookie: string;
  chartData: Array<any>; // You can define a more specific type if needed
}

function AnalyticsPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [analyticsData, setAnalyticsData] = useState<AnalyticsResponse | null>(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false); // Initialize to false
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState("");
  const [popoverOpened, setPopoverOpened] = useState(false);
 
  useTitle("Analyze Consent Records");

  useEffect(() => {
    if (props.currentConsentData) {
      setDomainId(props.currentConsentData.domainId);
    }
  }, [props.currentConsentData]);
  
  // Custom function to format dates as "YYYY-MM-DD"
  const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD format
  };

  const handleDateRangeChange = (filter:any) => {
    const today = new Date();
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    let interval: string | null = null;

    if(filter !== 'Specific date range') {
      switch (filter) {
        case "Today":
          startDate = today;
          endDate = today;
          interval = 'day';
          break;
        case "Yesterday":
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 1);
          endDate = new Date(today);
          break;
        case "Last 7 Days":
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 7);
          endDate = today;
          break;
        case "Last 30 Days":
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 30);
          endDate = today;
          break;
        default:
          startDate = form.values.startDate;
          endDate = form.values.endDate;
      }
    }

    form.values.startDate = startDate;
    form.values.interval = interval;
    form.values.endDate = endDate;
    setSelectedRange(filter);

    if(filter !== 'Specific date range') {
      fetchAnalyticsData();
      setPopoverOpened(false);
    }
  };

  // Form validation for the date pickers
  const form = useForm({
    initialValues: {
      startDate: null as Date | null,
      endDate: null as Date | null,
      interval: null as string | null
    },
    validate: {
      startDate: (value) => (!value ? "Start date is required" : null),
      endDate: (value) => (!value ? "End date is required" : null),
    },
  });

  const formatAverageTimeToConsent = (timeInSeconds:number) => {
    const roundedTime = Math.round(timeInSeconds);
    
    if (roundedTime < 60) {
      return `${roundedTime} sec`;
    } else {
      const minutes = Math.floor(roundedTime / 60);
      const seconds = roundedTime % 60;
      return `${minutes} min ${seconds} sec`;
    }
  };

  const data = [
    {
      title: "Total Opt-ins",
      subTitle: '',
      description: analyticsData?.totalOptIns.toString(),
      icon: <IconDoorEnter className="primary" size={70} stroke={1}/>,
      color: "var(--mantine-primary-color-filled)",
      bgColor: "#FFE5B4",
    },
    {
      title: "Total Opt-outs",
      subTitle: '',
      description: analyticsData?.totalOptOuts.toString(),
      icon: <IconDoorExit size={70} color="orange" stroke={1} />,
      color: "orange",
      bgColor: "#f2f4fa",
    },
    {
      title: "Average Time to Consent",
      subTitle: '',
      description: analyticsData?.averageTimeToConsent ? formatAverageTimeToConsent(analyticsData.averageTimeToConsent): "N/A",      
      icon: <IconClockRecord size={70} color="gray" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
    {
      title: "Most Consented Cookie",
      subTitle: 'cookie that users most likely give consent to: ',
      description: analyticsData?.mostAcceptedCookie.toString(),
      icon: <IconChartPie size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
    {
      title: "Least Consented Cookie",
      subTitle: 'cookie that users least likely give consent to: ',
      description: analyticsData?.leastAcceptedCookie.toString(),
      icon: <IconChartPie size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
  ];

  const fetchAnalyticsData = async () => {
    if (domainId) {
      setLoading(true);
      try {
        const response = await axios.get<AnalyticsResponse>(
          `${config!.apiBaseUrl}/domains/${domainId}/analytics`,
          {
            params: {
              startDate: formatDate(form.values.startDate),
              endDate: formatDate(form.values.endDate),
              interval: form.values.interval
            },
            headers: getAuthorizationHeader()
          }
        );
        setAnalyticsData(response.data);
        setIsDataAvailable(response.data.chartData.length > 0);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
        setIsDataAvailable(false);
      } finally {
        setLoading(false);
      }
    }
  };
  
  useEffect(() => {
    fetchAnalyticsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId, form.values.startDate, form.values.endDate]);
  
  const handleFilterClick = () => {
    if (form.validate().hasErrors === false) {
      setTimeout(() => {
        fetchAnalyticsData();
        console.log("Delayed for 1 second.");
      }, 1000);
    }
  };

  const handleDownloadCSV = async () => {
    if (!domainId) {
      // Show error message about missing domain
      return;
    }

    try {
      const response = await fetch(
        `${config!.apiBaseUrl}/domains/${domainId}/consents?startDate=${formatDate(form.values.startDate)}&endDate=${formatDate(form.values.endDate)}`,
        {
          method: 'GET',
          headers: getAuthorizationHeader()
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to download CSV: ${response.statusText}`);
      }

      // Convert the response to a blob and create a download link
      const blob = await response.blob();
      const filename = `consents_${formatDate(form.values.startDate)}_${formatDate(form.values.endDate)}.csv`;

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link); // Remove the link after download
      window.URL.revokeObjectURL(url); // Clean up the object URL

    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
    <>
      <Container fluid>
        <Title mt="lg" mb="lg" order={2}>
          Analytics
        </Title>
        <Card withBorder shadow="sm" radius="md">
          <Card.Section withBorder inheritPadding mt="sm" pb="md">
            <Grid align="center">
              <Grid.Col span="auto"></Grid.Col>
              <Grid.Col span="content">
                <Group justify="flex-end">
                  <Popover
                    width={350}
                    position="right-end"
                    withArrow
                    shadow="md"
                    opened={popoverOpened}
                    onClose={() => setPopoverOpened(false)}
                    closeOnClickOutside={false}
                  >
                    <Popover.Target>
                      <Button
                        leftSection={
                          <IconFilter
                            style={{ width: rem(18), height: rem(18) }}
                            stroke={1.5}
                          />
                        }
                        onClick={() => setPopoverOpened((o) => !o)}
                      >
                        Filter
                      </Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                    <Group justify="right">
                      <ActionIcon
                        variant="subtle"
                        onClick={() => setPopoverOpened(false)}
                        aria-label="Close"
                      >
                        <IconX size={16} />
                      </ActionIcon>
                    </Group>
                      <Stack>
                        <Radio.Group
                          value={selectedRange}
                          onChange={handleDateRangeChange}
                        >
                          <Radio mt={10} value="Today" label="Today" />
                          <Radio mt={10} value="Yesterday" label="Yesterday" />
                          <Radio mt={10} value="Last 7 Days" label="Last 7 Days" />
                          <Radio mt={10} value="Last 30 Days" label="Last 30 Days" />
                          <Radio mt={10} value="Specific date range" label="Custom Range" />
                        </Radio.Group>
                        {selectedRange === "Specific date range" && (
                          <form onSubmit={form.onSubmit(() => handleFilterClick())}>
                            <Group>
                              <DatePickerInput
                                mt={10}
                                label="FROM"
                                placeholder="Start Date"
                                size="xs"
                                leftSectionPointerEvents="none"
                                rightSection={
                                  <IconCalendar
                                    style={{ width: rem(18), height: rem(18) }}
                                    stroke={1.5}
                                  />
                                }
                                value={form.values.startDate}
                                onChange={(date) => form.setFieldValue("startDate", date)}
                                error={form.errors.startDate}
                              />
                              <DatePickerInput
                                mt={10}
                                placeholder="End Date"
                                label="TO"
                                size="xs"
                                leftSectionPointerEvents="none"
                                rightSection={
                                  <IconCalendar
                                    style={{ width: rem(18), height: rem(18) }}
                                    stroke={1.5}
                                  />
                                }
                                value={form.values.endDate}
                                onChange={(date) => form.setFieldValue("endDate", date)}
                                error={form.errors.endDate}
                              />
                            </Group>
                            <Button size="xs" variant="outline" mt={10} type="submit">
                              Filter
                            </Button>
                          </form>
                        )}
                      </Stack>
                    </Popover.Dropdown>
                  </Popover>
                  <div>
                    {analyticsData?.chartData && analyticsData?.chartData.length > 0 && (
                    <Button
                      leftSection={
                        <IconDownload
                          style={{ width: rem(18), height: rem(18) }}
                          stroke={1.5}
                        />
                      }
                      variant="outline"
                      onClick={() => handleDownloadCSV()}
                    >
                      Download Consent log 
                    </Button>
                    )}
                    </div>
                </Group>
              </Grid.Col>
            </Grid>
          </Card.Section>
                     
          <Card.Section withBorder inheritPadding mt="sm" pb="md">
            {isDataAvailable ? (
              <React.Fragment>
                  <SimpleGrid cols={3}>
                    {data
                      .filter((stat) => 
                        stat.title !== "Most Consented Cookie" && stat.title !== "Least Consented Cookie"
                      )
                      .map((stat, index) => (
                        <div key={index}>
                          <Paper shadow="sm" p="sm" withBorder bg="#F4F6FB">
                            <Text>{stat.title}</Text>
                            {stat.subTitle && <Text size="xs">{stat.subTitle}</Text>}
                            <Group justify="apart" mt={10}>
                              {stat.icon}
                              <div>
                                <Text fw={500} style={{ fontSize: "23px" }} w={140}>
                                  {stat.description}
                                </Text>
                              </div>
                            </Group>
                          </Paper>
                        </div>
                      ))}
                  </SimpleGrid>
                  <Box mt={10} mb={20}>
                    <AnalyticsChart chartData={analyticsData?.chartData} />
                  </Box>
                  <SimpleGrid cols={3}>
                    {data
                      .filter((stat) => stat.title === "Most Consented Cookie" || stat.title === "Least Consented Cookie")
                      .map((stat, index) => (
                        <div key={index}>
                          <Paper shadow="sm" p="sm" withBorder bg="#F4F6FB">
                            <Text>{stat.title}</Text>
                            {stat.subTitle && <Text size="xs">{stat.subTitle}</Text>}
                            <Group justify="apart" mt={10}>
                              {stat.icon}
                              <div>
                                <Text fw={500} style={{ fontSize: "23px" }} w={140}>
                                  {stat.description}
                                </Text>
                              </div>
                            </Group>
                          </Paper>
                        </div>
                      ))}
                  </SimpleGrid>
                <Space h="xl" />
              </React.Fragment>
            ) : (
              <Center>
                <Text>No Data Available</Text> 
              </Center>// Display message when no data
            )}
          </Card.Section>
        </Card>
      </Container>
      </>
    </React.Fragment>
  );
}

export { AnalyticsPage };
