import { Button, Card, Code, Divider, Group, Stack, Text, Textarea, Title } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconCopy } from "@tabler/icons-react";
import { ConfigContext } from "../../../Context/configContext";
import { useContext } from "react";

function Integration(props:any) {
    const { config } = useContext(ConfigContext);
    const clipboard = useClipboard({ timeout: 500 });
    
    const embScript = `
        <script id="cookiex" data-ckid=${props.domainId} src="${config?.bannerUrl}/cookiex.min.js" type="text/javascript"></script>
        <script type="text/javascript">
        document.addEventListener("DOMContentLoaded", function() {
                const theme = {
                    domainId: '${props.domainId}',
                    domainName: '${props.domainName}'
                };
                const cookiex = new Cookiex();
                cookiex.init(theme);
        });
        </script>
    `;
    return (
      <>
      <Stack  gap={10} p="sm" pl="xl" pr="xl">
        <Title order={2} fw={500} ta="center">Integration</Title>
        <Text size="md" fw={700}>
            Embed Script
        </Text>
        <Text size="xs" mt={10}>
            Copy the script tag and insert it as the very first script within the HEAD-tag of your website to display the cookie consent banner.
        </Text>
        <Card mt={10} p={0} shadow="0" withBorder style={{ width: '100%' }}>
            <Group justify="apart" grow>
                <Code color="white" style={{minWidth:'250px'}}>
                    <Text size="xs" truncate="end">{embScript}</Text>
                </Code>
                <Text mb="xs" mt="sm" ta="center" color="blue" style={{cursor: 'pointer'}} onClick={() => {
                    clipboard.copy(embScript)
                        showNotification({ color: "green", message: "Copied to clipboard !"});
                    }
                }>
                    <IconCopy color="blue" size={18} />
                </Text>
            </Group>
        </Card>
        <Divider my="xs" label="OR" labelPosition="center" />
        <Card withBorder p="sm" radius="md" bg="yellow.1">
            <Text size="xs">
                ⚠️ If you already use our <b>WordPress plugin</b>, do not install the code. 
                You can directly <Text component="a" href={`${props.domainName.startsWith("http") ? props.domainName : `https://${props.domainName}`}/wp-admin/admin.php?page=cookiex`} target="_blank" color="blue" fw={700}>
                    connect plugin to our web app
                </Text>.
        </Text>
        </Card>
        <Group mt={20} justify="apart" grow>
          <Button variant="default" onClick={props.prevStep}>Back</Button>
          <Button onClick={props.nextStep}>Next</Button>
        </Group>
      </Stack>
      </> 
    );
  }

  export { Integration };