import {
  LoadingOverlay,
  PasswordInput,
  Paper,
  Button,
  Image,
  Title,
  Grid,
  Card,
  Text,
  em
} from "@mantine/core";
import React, { useState, useRef, useEffect, useContext } from "react";
import logo from "../images/logo.png";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useNavigate, useSearchParams } from 'react-router-dom';
import loginBanner from '../images/login_banner.png';
import './login.css';
import { jwtDecode } from 'jwt-decode';
import { useMediaQuery } from '@mantine/hooks';
import { ConfigContext } from "../Context/configContext";
import { AuthService } from "../services/AuthService";

function UpdatePassword(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const initialized = useRef(false);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const authService = new AuthService(config!);

  const verifyResetCode = async(code:any) => {
      setLoading(true);
      await authService.verifyCode(code)
      .then((res:any) => {
         setLoading(false)
         if(res?.response?.status == 410) {
            navigate("/update?message=EXPIRED");
         }
      })
      .catch((e) => {
          showNotification({ color: "red", message: "Something went wrong!" });
          setLoading(false);
      });
  };

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true
        if(code) {
            try {
              const decoded = jwtDecode(code); // Assuming jwtDecode decodes the JWT token
              const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
              const expirationTime = decoded.exp; // Expiration time from JWT payload
              if (expirationTime && expirationTime < currentTime) {
                 navigate("/update?message=EXPIRED");
              }
              verifyResetCode(code);
            } catch (error) {
              navigate("/update?message=EXPIRED");
            }
        }
      }
      console.log("test");
  }, [initialized, code, navigate]); // eslint-disable-line react-hooks/exhaustive-deps


  const form = useForm({
      initialValues: {password: '', confirmPassword:''},
      validate: {
        password: (value) => !value ? "Password is required": value.length < 8 ? 'Password should be min 8 characters':null,
        confirmPassword: (value, values) => (!value?'Confirm Password is required':value !== values.password ? 'Passwords did not match' : null)
      },
  });

  const updateUserPassword = async (values:any) => {
      setLoading(true);

      if(!code) {
          showNotification({ color: "red", message: "Invalid URL"});
          setLoading(false)
          return;
      }

      try {
          if(values.password && values.confirmPassword && code) {
              await authService.updatePassword(values.password, values.confirmPassword, code).then((res:any) => {
                   setLoading(false)
                   if (res?.statusText == 'OK') {
                     setLoading(false);
                     navigate("/update?message=SUCCESS");
                   } else {
                     navigate("/update?message=FAILED");
                   }
              })
          }
      } catch (error) {
           showNotification({ color: "red", message: "Something went wrong !" });
      }
  };

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
      <div className="login-page">
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }} mr={0} bg="#F4F6FB" visibleFrom="sm">
            <Card bg="#F4F6FB">
              <Card.Section  className="login-image">
                <Text ta="center" c="black" fw="700" className="large-text" mb={40}>
                    Empower your website's data privacy with Cookiex to seamlessly
                    manage cookie consent and compliance today!
                </Text>
                <Image src={loginBanner} />
              </Card.Section>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }} ml={0} mt={isMobile?80:0}>
              <Paper className="login-left">
                   <div>
                     <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                     <Title order={3} ta="center" mt="md" mb="xl">Reset Your Password</Title>
                     <Paper className="login-padding">
                        <form onSubmit={form.onSubmit(updateUserPassword)}>
                            <Title order={6} mt="md" mb="xs">Password</Title>
                            <PasswordInput withAsterisk  radius="xs" size="md"  placeholder="Minimum 8 characters"  {...form.getInputProps('password')} />
                            <Title order={6} mt="md" mb="xs">Confirm Password</Title>
                            <PasswordInput withAsterisk  radius="xs" size="md"  placeholder="Re-enter password"  {...form.getInputProps('confirmPassword')} />
                            <Button type="submit" fullWidth mt="xl" mb="xs"> Reset Password </Button>
                        </form>
                     </Paper>
                   </div>
              </Paper>
          </Grid.Col>
        </Grid>
      </div>
      </>
    </React.Fragment>
  );
}

export { UpdatePassword };
