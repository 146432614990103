import { useContext } from "react";
import {
  Image,
  Group,
  Burger,
  em,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ConsentService } from "../../services/consentService";
import { ConfigContext } from "../../Context/configContext";
import DomainDropdown from "../account/DomainDropdown";
import classes from "./header.module.css";
import logo from "../../images/logo.png";

export function AppHeader(props: any) {
  const { config } = useContext(ConfigContext);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const consentService = new ConsentService(config!);

  const handleCurrentDomain = (domainId: string, domainName: any) => {
    localStorage.setItem("activeDomainId", domainId);
    localStorage.setItem("activeDomain", domainName);
    if (props.activeConsentData) {
      props.activeConsentData({ domainId, domain: domainName });
    }
  };

  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <Group align="center">
          <Burger
            opened={props.opened}
            onClick={props.toggle}
            hiddenFrom="sm"
            size="sm"
          />
          <Image
            src={logo}
            alt="Logo"
            style={{
              width: isMobile ? "70px" : "170px",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Group>

        <Group className={classes.domainSelectWrapper}>
          <DomainDropdown
            handleCurrentDomain={handleCurrentDomain}
            consentService={consentService}
            defaultSelection={localStorage.getItem("activeDomainId")}
            refresh={props.refresh}
          />
        </Group>
      </div>
    </header>
  );
}
