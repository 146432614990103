
import {
  Text,
  Group,
  Button,
  Card,
  Select,
  SimpleGrid,
  Radio,
  TextInput,
  LoadingOverlay,
} from "@mantine/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import countries from '../../data/countries.json'; // Adjust the path as necessary
import { UserService } from "../../services/userService";
import { ConfigContext } from "../../Context/configContext";

function ProfileInformation(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);

  const userService = new UserService(config!);

  const validateForm = () => {
    const errors: any = {};
  
    const values = form.values;
  
    if (!props.isBilling) {
      if (!values.accountType) errors.accountType = "Account Type is required";
      if (!values.invoiceEmail) {
        errors.invoiceEmail = "Invoice email is required";
      } else if (!emailPattern.test(values.invoiceEmail)) {
        errors.invoiceEmail = "Invalid email";
      }
      if (!values.purchaseOrderNumber) errors.purchaseOrderNumber = "Purchase Order Number is required";
      if (!values.vatNumber) errors.vatNumber = "VAT Number is required";
      if (!values.companyName) errors.companyName = "Company Name is required";
      if (!values.billingCycle) errors.billingCycle = "Billing Cycle is required";
      if (!values.currency) errors.currency = "Currency is required";
      if (!values.language) errors.language = "Language is required";
    }
  
    if (props.isBilling) {
      if (!values.firstName) errors.firstName = "First Name is required";
      if (!values.lastName) errors.lastName = "Last Name is required";
      if (!values.country) errors.country = "Country is required";
      if (!values.city) errors.city = "City is required";
      if (!values.postalCode) errors.postalCode = "Postal Code is required";
    }
  
    form.setErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  

  const form = useForm({
      initialValues: {
        accountType: '',
        invoiceEmail: '',
        purchaseOrderNumber: '',
        vatNumber: '',
        companyName: '',
        billingCycle: '',
        currency: '',
        language: '',
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        city: '',
        postalCode: '',
      },     
  });

  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true
        getProfile();
      }
      console.log("test");
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const getProfile = () => {
     setLoading(true);
     const response = userService.getProfile();
     response.then((res) => {
          setLoading(false);
          if(res){
            console.log(res);
            form.setValues(res);
          }
     });
  };

  const handleProfileInformation = async (values:any) => {
      setLoading(true);
      const response = userService.saveOrUpdateProfile(values);
      response.then((res) => {
         setLoading(false);
         getProfile();
         showNotification({ color: "green", message: "Profile updated!" });
      });
  };

  return (
    <React.Fragment>
      {loading && (
        <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
      <Card shadow="0">
        {form && (
          <form onSubmit={(e) => {
            e.preventDefault();
            if (validateForm()) {
              handleProfileInformation(form.values);
            }
          }}>
               {!props.isBilling && (
               <>
               <Card.Section withBorder inheritPadding py="xs">
                 <Radio.Group label="Account Type" mt={10} mb={20} {...form.getInputProps('accountType')}>
                   <Group mt="xs">
                     <Radio value="Company" label="Company" />
                     <Radio value="OrganisationNGO" label="Organisation/NGO" />
                     <Radio value="PrivateIndividual" label="private Individual" />
                   </Group>
                 </Radio.Group>
               </Card.Section>
               <Card.Section withBorder inheritPadding py="xs">
                 <Text size="sm" mt={10} fw={600}>
                   Invoice Information
                 </Text>
                 <SimpleGrid cols={2} mt={10} mb={20}>
                   <TextInput placeholder="E.g invoice@gmail.com" label="Invoice e-mail" {...form.getInputProps('invoiceEmail')} />
                   <TextInput placeholder="Enter PO number" label="Purchase order number" {...form.getInputProps('purchaseOrderNumber')} />
                   <TextInput placeholder="Enter Company Name" label="Company name" {...form.getInputProps('companyName')} />
                   <TextInput placeholder="Enter VAT" label="VAT number"  {...form.getInputProps('vatNumber')} />
                   <Select defaultValue="Monthly" label="Billing cycle" placeholder="" data={["Monthly", "Yearly"]} {...form.getInputProps('billingCycle')} />
                   <Select defaultValue="USD" label="Currency" placeholder="" data={["USD", "INR"]}  {...form.getInputProps('currency')}/>
                   <Select defaultValue="English" label="Language" placeholder="" data={["English"]}  {...form.getInputProps('language')}/>
                 </SimpleGrid>
               </Card.Section>
               </>
               )}
               {props.isBilling && (
                <>
               <Card.Section withBorder inheritPadding py="xs">
                 <SimpleGrid cols={2} mt={10}>
                   <TextInput placeholder="Enter first name" label="First Name" {...form.getInputProps('firstName')} />
                   <TextInput placeholder="Enter last name" label="Last Name"  {...form.getInputProps('lastName')}/>
                 </SimpleGrid>

                 <SimpleGrid cols={1} mt={10}>
                   <TextInput placeholder="Address Line 1" label="Address" {...form.getInputProps('addressLine1')} />
                   <TextInput placeholder="Address Line 2 (Optional)" {...form.getInputProps('addressLine2')} />
                 </SimpleGrid>

                 <SimpleGrid cols={2} mt={20} mb={20}>
                   <Select placeholder="Select country" label="Country" data={countries} searchable {...form.getInputProps('country')} />
                   <TextInput placeholder="Enter postal code" label="Postal code" {...form.getInputProps('city')} />
                   <TextInput placeholder="City" label="City" {...form.getInputProps('postalCode')} />
                 </SimpleGrid>
               </Card.Section>
               </>
               )}
               <Card.Section withBorder inheritPadding py="xs">
                 <Button type="submit" w={150} mt={10} variant="filled" size="xs">
                   Save Changes
                 </Button>
               </Card.Section>
            </form>
          )}
         </Card>
      </>
    </React.Fragment>
  );
}

export { ProfileInformation };
