import axios from 'axios';
import { getAuthorizationHeader } from "../utils/getAuthorizationHeader";
import { Config } from '../utils/types';

export class PricingService {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  public getPlans(domainId: string) {
    const url = this.config.apiBaseUrl + "/domains/" + domainId + "/plans";
    try {
      return axios.get(`${url}`,
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public getSubscriptions(domainId: string) {
    const url = this.config.apiBaseUrl + "/domains/" + domainId + "/subscriptions";
    try {
      return axios.get(`${url}`,
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async savePlan(data: any, domainId: string): Promise<any>  {
    const url = this.config.apiBaseUrl + "/domains/" + domainId + "/subscription";
    try {
      const response = await axios.post(`${url}`,
          data,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  // **Delete Plan API**
  public async deletePlan(domainId: string): Promise<any> {
    const url = `${this.config.apiBaseUrl}/domains/${domainId}/subscription`;
    try {
      const response = await axios.delete(url, {
        headers: getAuthorizationHeader(),
      });
      
      return response.data;
    } catch (error) {
      console.error("Failed to delete plan:", error);
      throw error;
    }
  }

  // **Update Plan API**
  public async updatePlan(domainId: string, data: any): Promise<any> {
    const url = `${this.config.apiBaseUrl}/domains/${domainId}/subscription`;
    try {
      const response = await axios.put(url, data, {
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Failed to update plan:", error);
      throw error;
    }
  }
};
