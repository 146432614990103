import React, { useState, useContext, useEffect } from "react";
import { Group, LoadingOverlay, Modal, Stepper, Text } from "@mantine/core";
import { AddDomain } from "./AddDomain";
import { ConsentConfiguration } from "./ConsentConfiguration";
import { showNotification } from "@mantine/notifications";
import { ConsentService } from "../../../services/consentService";
import { ConfigContext } from "../../../Context/configContext";
import { Integration } from "./Integration";
import { Completion } from "./Completion";

function Wizard(props: any) {
  const [opened, setOpened] = useState(true);
  const [activeStep, setActiveStep] = useState(props.domainId ? 2 : 1);
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState(props.domainId || '');
  const [domainName, setDomainName] = useState(props.domain || '');
  const [consentConfigRequest ] = useState<any>({language:'en',domainId:'',domainUrl:'',geoLocations:[],consentExpire:'',styles:'',locations:[]});
  const [loading, setLoading] = useState(false);
  
  const consentService = new ConsentService(config!);

  const handleConsentDomain = (fieldValue:any, fieldName:any) => {
    consentConfigRequest[fieldName] = fieldValue;
    setDomainName(fieldValue);
    nextStep();
  };

  const handleConsentExpire = (fieldValue:any, fieldName:any) => {
    consentConfigRequest[fieldName] = fieldValue;
  };

  const handleConsentData = async (request: any) => {
    setLoading(true);

    const updatedRequest = {
      ...consentConfigRequest,
      styles: request,
      geoLocations: ['Asia-Pacific'],
      subdomainConsentSharing: true,
      consentLog: true
    };

    try {
      let resp: any;
      
      if (domainId) {
        // ✅ **Update API Call**
        console.log("[Wizard] Updating existing consent configuration...");
        resp = await consentService.updateConsent(domainId, updatedRequest);
        showNotification({ color: "green", message: "Consent configuration updated successfully!" });
      } else {
        // ✅ **Save API Call**
        console.log("[Wizard] Saving new consent configuration...");
        resp = await consentService.saveConsent(updatedRequest);
      if (resp?.id) {
          setDomainId(resp.id);
          setDomainName(resp.domainUrl);
          showNotification({ color: "green", message: "Consent configuration saved successfully!" });
        }
      }
    
      nextStep();
    } catch (error: any) {
      console.error("[Wizard] API error:", error);
      showNotification({ color: "red", message: "Failed to save consent configuration." });
    } finally {
      setLoading(false);
    }
  };
  
  const nextStep = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    setActiveStep((current: number) => current + 1);
    setOpened(true); // 🔥 **Ensure modal remains open**
  };

  const prevStep = () => {
    setActiveStep((current: number) => (current > 1 ? current - 1 : current));
    setOpened(true); // 🔥 **Ensure modal remains open**
  };
  
  const handleRefresh = (domainID:any) => {
    props.handleRefresh(domainID);
  };

  const close = () => setOpened(false);

  return (
    <React.Fragment>
      {activeStep && (
            <Modal withCloseButton={false} opened={opened} onClose={close} centered>
                <Stepper active={activeStep} onStepClick={setActiveStep} size="md" mt="xl">
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                    <Stepper.Step label="" />
                </Stepper>

                <Group justify="right" pr={20} pt={10}>
                    <Text size="xs" style={{ cursor: 'pointer' }} onClick={close}>Skip</Text>
                </Group>

                {activeStep === 1 && (
                    <>
              {loading && <LoadingOverlay visible overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }} />}
                        <AddDomain domainName={domainName} handleConsentDomain={handleConsentDomain} />
                    </>
                )}

                {activeStep === 2 && (
                 <>
              {loading && <LoadingOverlay visible overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }} />}
              <ConsentConfiguration
                prevStep={prevStep}
                consentData={handleConsentData}
                handleExpire={handleConsentExpire}
                domainId={domainId}
              />
                 </>
                )}

          {activeStep === 3 && <Integration nextStep={nextStep} prevStep={prevStep} domainId={domainId} domainName={props.domainName || domainName} />}
                {activeStep === 4 && <Completion prevStep={prevStep} close={close} domainId={domainId} domainName={domainName} handleRefresh={handleRefresh} />}
            </Modal>
      )}
    </React.Fragment>
  );
}

export { Wizard };
