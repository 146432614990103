import {
  LoadingOverlay,
  TextInput,
  PasswordInput,
  Paper,
  Text,
  Group,
  Card,
  Button,
  Image,
  Title,
  Grid,
  Flex,
  Space,
  Center,
  em
} from "@mantine/core";
import React, { useContext, useState } from "react";
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';
import loginBanner from '../images/login_banner.png';
import './login.css';
import { useAuth } from "../Context/useAuth";
import { AuthService } from "../services/AuthService";
import { useMediaQuery } from '@mantine/hooks';
import { ConfigContext } from "../Context/configContext";

function LogInPage(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const { loginUser } = useAuth();
  const [time, setTime] = useState("01:");
  const [seconds, setSeconds] = useState(60);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [email, setEmail] = useState(null);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const location = useLocation();

  const authService = new AuthService(config!);

  const form = useForm({
      initialValues: {email: '',password: ''},
      validate: {
        email: (value) => !value ? 'Email is required':(emailPattern.test(value) ? null : 'Invalid e-mail'),
        password: (value) => !value ? "Password is required":value.length < 8 ? 'The password must be at least 8 characters long':null
      },
  });

  const timer = () => {
    let intervalId;
    let secondsLeft = 60;
    setTime('01:');
    intervalId = setInterval(() => {
      setTime('00:');
      if (secondsLeft > 0) {
        setSeconds(secondsLeft);
        setSeconds(secondsLeft - 1);
      } else {
        setIsResend(true);
        clearInterval(intervalId);
      }
      secondsLeft--;
    }, 1000);
  };

  const resendVerificationEmail = async () => {
      setLoading(true);
      setTime('01:');
      try {
          if(email) {
              await authService.resendEmail(email, 'verification').then((res:any) => {
                   setLoading(false)
                   if (res?.statusText == 'OK') {
                     setTimeout(function () {
                       setSeconds(60);
                       timer();
                       showNotification({ color: "green", message: "Verification email resent successfully"});
                       setIsEmailSent(true);
                     }, 1000);
                   } else {
                       if(res?.response?.data == 'Failed to send verification link') {
                           setIsEmailSent(false);
                       }
                       showNotification({ color: "red", message: res?.response?.data});
                   }
              })
          }
      } catch (error) {
           showNotification({ color: "red", message: "Something went wrong !" });
      }
  };

  const handleLogin = async(values:any) => {
    setLoading(true);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("activeDomain");
    localStorage.removeItem("activeDomainId");
    setEmail(values.email);
    await authService.login(values.email, values.password)
      .then((res:any) => {
        if (res) {
          setLoading(false);
          if(res?.status == 200) {
            loginUser(res, location.state?.from);
          } else {
            showNotification({ color: "red", message: res?.response?.data });
            if(res?.response?.data == 'Your email verification is pending. Please check your inbox and click the verification link to activate your account.') {
              setIsEmailVerified(false);
            } else {
              setIsEmailVerified(true);
            }
          }
        }
        setLoading(false);
      })
      .catch((e) => showNotification({ color: "red", message: "Something went wrong!" }));
  };

  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
      <>
        <div className="login-page">
          <Grid>
            <Grid.Col span={{ base: 12, sm: 6, lg: 6 }} mr={0} bg="#F4F6FB" visibleFrom="sm">
                <Card bg="#F4F6FB">
                  <Card.Section  className="login-image">
                    <Text ta="center" c="black" fw="700" className="large-text" mb={40}>
                        Empower your website's data privacy with Cookiex to seamlessly
                        manage cookie consent and compliance today!
                    </Text>
                    <Image src={loginBanner} />
                  </Card.Section>
                </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6, lg: 6 }} ml={0} mt={isMobile?80:0}>
                <Paper className="login-left">
                     <div>
                       {!isEmailSent && (
                       <div>
                           <Image maw={120} mx="auto" mt="xs" radius="md" src={logo} />
                           <Title order={3} ta="center" mt="xs" mb="xl">Welcome back!</Title>
                           <Paper className="login-padding">
                              <form onSubmit={form.onSubmit(handleLogin)}>
                                 <Title order={6} mt="md" mb="xs">Email address</Title>
                                 <TextInput className="login-input" radius="xs" size="md"placeholder="name@example.com" {...form.getInputProps('email')} />
                                 <Title order={6} mt="md" mb="xs">Password</Title>
                                 <PasswordInput className="login-input" radius="xs" size="md" placeholder="Minimum 8 characters" {...form.getInputProps('password')} />
                                 <Group justify="space-between" mt="sm">
                                    <Text ta="center" c="dimmed" size="sm" fw={500}>
                                        <Link to="/resetPassword"> Forgot your password? </Link>
                                    </Text>
                                 </Group>
                                 <Button type="submit" fullWidth mb="lg" mt="sm"> Login </Button>
                                 <Link to="/register">
                                  <Flex justify="center">
                                      <Text ta="center" c="black" size="sm" mt={20} fw={500}>
                                         Do not have an account ?{" "}
                                      </Text>
                                      <Space w={3} />
                                      <Text ta="center" c="blue" size="sm" mt={20} fw={500}>
                                           Sign Up
                                      </Text>
                                  </Flex>
                                 </Link>
                               </form>
                           </Paper>
                       </div>
                       )}
                       {!isEmailVerified && !isEmailSent && (
                           <Flex justify="center">
                                  <Text c="black" size="sm" mt={20} fw={500}>
                                     Email not verified yet?{" "}
                                  </Text>
                                  <Space w={3} />
                                  <Text style={{cursor:'pointer'}} c="blue" size="sm" mt={20} fw={500} onClick={resendVerificationEmail}>
                                      Resend Link
                                  </Text>
                           </Flex>
                       )}

                       {!isEmailVerified && isEmailSent && (
                          <div className="login-left override-cls">
                               <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                               <Title order={3} ta="center" mt="md">Please Check Your Inbox</Title>
                               <div className="login-padding">
                                   <Text size="sm" ta="center" mt="sm">An email has been sent to verify your email address.</Text>
                                        <Center mt="xl">
                                           <Text>Didn't receive email? Resend in </Text>
                                           <Space w={5} />
                                           <Text fw={500}>{time}</Text>
                                           <Text fw={500}>{seconds < 10?'0'+seconds:seconds==60?'00':seconds}</Text>
                                      </Center>
                                  <div>
                                    {isResend && (
                                         <Button type="submit" fullWidth mt="xl" mb="xs" onClick={resendVerificationEmail}> Resend </Button>
                                     )}
                                     {!isResend && (
                                       <Button disabled fullWidth mt="xl" mb="xs"> Resend </Button>
                                     )}
                                  </div>
                               </div>
                          </div>
                       )}
                    </div>
                </Paper>
            </Grid.Col>
          </Grid>
        </div>
       </>
    </React.Fragment>
  );
}

export { LogInPage };
