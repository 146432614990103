import {
    LoadingOverlay,
    TextInput,
    PasswordInput,
    Paper,
    Text,
    Button,
    Image,
    Title,
    Grid,
    Space,
    Card,
    Flex,
    Center,
    em,
    Alert
  } from "@mantine/core";
  import React, { useContext, useEffect, useState } from "react";
  import logo from "../images/logo.png";
  import { useAuth } from "../Context/useAuth";
  import { useForm } from '@mantine/form';
  import { showNotification } from '@mantine/notifications';
  import { Link, useSearchParams } from 'react-router-dom';
  import './login.css';
  import loginBanner from '../images/login_banner.png';
  import { AuthService } from "../services/AuthService";
  import { useMediaQuery } from '@mantine/hooks';
  import { ConfigContext } from "../Context/configContext";
import { IconInfoCircle } from "@tabler/icons-react";
  
  function Connect(): JSX.Element {
    const { config } = useContext(ConfigContext);
    const [loading, setLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(true);
    const [email, setEmail] = useState(null);
    const [isAccountCreated, setIsAccountCreated] = useState(false);
    const [time, setTime] = useState("01:");
    const [seconds, setSeconds] = useState(60);
    const [isResend, setIsResend] = useState(false);
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
    const [searchParams] = useSearchParams();
    const authService = new AuthService(config!);
    const [domainUrl, setDomainUrl] = useState<string>("");
    const [mode, setMode] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const { loginUser } = useAuth();

    const form = useForm({
        initialValues: {email: '',password: ''},
        validate: {
          email: (value) => !value ? 'Email is required':(emailPattern.test(value) ? null : 'Invalid email'),
          password: (value) => !value ? "Password is required": value.length < 8 ? 'Password should be min 8 characters':null,
         },
    });
  
    useEffect(() => {
      const websiteParam = searchParams.get("website");
      if(websiteParam)
        setDomainUrl(websiteParam);

      const modeInfo = searchParams.get("mode");
      if(modeInfo)
        setMode(modeInfo);

      const authToken = searchParams.get("token");
      if(authToken)
        setToken(window.atob(authToken));
    
    }, [searchParams]);
  
    const timer = () => {
        let intervalId:any;
        let secondsLeft = 60;
        setTime('01:');
        intervalId = setInterval(() => {
          setTime('00:');
          if (secondsLeft > 0) {
            setSeconds(secondsLeft);
            setSeconds(secondsLeft - 1);
          } else {
            setIsResend(true);
            clearInterval(intervalId);
          }
          secondsLeft--;
        }, 1000);
    };
  
    const handleConnect = async (values: any) => {
      setLoading(true);
      setEmail(values.email);
  
      try {
          const res : any = await authService.connect(values.email, values.password, mode, token);
  
          setLoading(false);
  
          switch (res?.status) {
              case 200:
                  showNotification({ color: "green", message: "Successfully connected with an existing account!" });
                  loginUser(res, location?.state?.from);
                  break;
  
              case 201:
                  setIsAccountCreated(true);
                  showNotification({ color: "green", message: "New account created! Please check your email for verification." });
                  timer();
                  break;
  
              case 202:
                  showNotification({ color: "green", message: "Account merged successfully with data migration." });
                  loginUser(res, location?.state?.from);
                  break;
  
              case 400:
                  showNotification({ color: "red", message: "This email is already registered. Enable merging or use a different email." });
                  break;
  
              case 401:
                  showNotification({ color: "yellow", message: res.response.data});
                  break;
  
              case 404:
                  showNotification({ color: "red", message: "Email not registered. Please try again or sign up." });
                  break;
  
              case 500:
                  showNotification({ color: "red", message: "Service temporarily unavailable. Please try again later." });
                  break;
  
              default:
                  showNotification({ color: "red", message: "Something went wrong. Please try again!" });
          }
      } catch (error) {
          setLoading(false);
          showNotification({ color: "red", message: "Error connecting to the server!" });
      }
    };
  
    const resendVerificationEmail = async () => {
      setLoading(true);
      setTime('01:');
      try {
          if(email) {
              await authService.resendEmail(email, 'verification').then((res:any) => {
                   setLoading(false)
                   if (res?.statusText == 'OK') {
                     setTimeout(function () {
                       setSeconds(60);
                       timer();
                       setIsResend(false);
                       setIsEmailSent(true);
                       showNotification({ color: "green", message: "Verification email resent successfully"});
                     }, 1000);
                   } else {
                       if(res?.response?.data == 'Failed to send verification link') {
                           setIsEmailSent(false);
                       }
                       showNotification({ color: "red", message: res?.response?.data});
                   }
              })
          }
      } catch (error) {
           showNotification({ color: "red", message: "Something went wrong !" });
      }
    };
  
    return (
      <React.Fragment>
        {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
        )}
        <>
          <div className="login-page">
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }} mr={0} bg="#F4F6FB" visibleFrom="sm">
                <Card bg="#F4F6FB">
                  <Card.Section  className="login-image">
                    <Text ta="center" c="black" fw="700" className="large-text" mb={40}>
                        Empower your website's data privacy with Cookiex to seamlessly
                        manage cookie consent and compliance today!
                    </Text>
                    <Image src={loginBanner} />
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }} ml={0} mt={isMobile?80:0}>
                  {isAccountCreated && (
                     <div className="login-left">
                          <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                          <Title order={3} ta="center" mt="md">Please Check Your Inbox</Title>
                          <div className="login-padding">
                              <Text size="sm" ta="center" mt="sm">An email has been sent to verify your email address.</Text>
                              {isEmailSent && (
                                  <Center mt="xl">
                                      <Text>Didn't receive email? Resend in </Text>
                                      <Space w={5} />
                                      <Text fw={500}>{time}</Text>
                                      <Text fw={500}>{seconds < 10?'0'+seconds:seconds==60?'00':seconds}</Text>
                                 </Center>
                              )}
                             <div>
                               {isResend && (
                                    <Button type="submit" fullWidth mt="xl" mb="xs" onClick={resendVerificationEmail}> Resend </Button>
                                )}
                                {!isResend && (
                                  <Button disabled fullWidth mt="xl" mb="xs"> Resend </Button>
                                )}
                             </div>
                          </div>
                     </div>
                  )}
  
                  {!isAccountCreated && (
                  <Paper className="login-left">
                       <div>
                         <Image maw={120} mx="auto" mt="lg" radius="md" src={logo} />
                         <Title order={3} ta="center" mt="md">{mode === 'register' ? 'Sign Up to Connect':'Log In to Connect'}</Title>
                         <Paper className="login-padding">
                            <Alert
                                icon={<IconInfoCircle size={20} />}
                                title={mode === 'login' ? 'By logging in, your website will be connected to your existing CookieX account.': `By signing up, your website ${domainUrl} be connected to a free CookieX account.`}
                                color="blue"
                                variant="light"
                                mt="md"
                                p="xs"
                            />
                            <form onSubmit={form.onSubmit(handleConnect)}>
                                <Title order={6} mt="md" mb="xs">Email address</Title>
                                <TextInput withAsterisk  radius="xs" size="md"  placeholder="name@example.com"  {...form.getInputProps('email')} />
                                <Title order={6} mt="md" mb="xs">Password</Title>
                                <PasswordInput withAsterisk  radius="xs" size="md"  placeholder="Minimum 8 characters" {...form.getInputProps('password')} />
                                {isEmailSent && (
                                  <Button type="submit" fullWidth mt="xl" mb="xs">{mode === 'register' ? 'Sign up & Connect to CookieX' : 'Log In & Connect to CookieX'}</Button>
                                )}
  
                                {!isEmailSent && (
                                  <Button color="blue" fullWidth mt="xl" mb="xl" onClick={resendVerificationEmail}> Resend Email </Button>
                                )}
                            </form>
                            <Flex justify="center" mt="md">
                                <Text size="sm">
                                    {mode === "register"
                                    ? "Already have an account?"
                                    : "Don't have an account?"}
                                </Text>
                                <Text style={{cursor: 'pointer'}}
                                    c="blue"
                                    size="sm"
                                    fw={700}
                                    ml={5}
                                    onClick={() => setMode(mode === "register" ? "login" : "register")}
                                >
                                    {mode === "register" ? "Log In" : "Sign Up"}
                                </Text>
                            </Flex>
                         </Paper>
                       </div>
                  </Paper>
                   )}
              </Grid.Col>
            </Grid>
          </div>
        </>
      </React.Fragment>
    );
  }
  
  export { Connect };
  