import {
  Container,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { BannerConfiguration } from "./consent/BannerConfiguration";
import { ConsentService } from "../../services/consentService";
import { showNotification } from '@mantine/notifications';
import { ConfigContext } from "../../Context/configContext";
import { useTitle } from "../hooks/useTitle";
import { themesConfig } from "@/utils/utils";

const tempObj = {language:'en',domainId:'',domainUrl:'',geoLocations:[],consentExpire:'',styles:'', locations:[]};
function ConsentBannerPage(props:any): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId, setDomainId] = useState<any | null>(props?.currentConsentData?.domainId || localStorage.getItem('activeDomainId'));
  const [domainName, setDomainName] = useState<any | null>(localStorage.getItem('activeDomain'));
  const [consentConfigRequest, setConsentConfigRequest] = useState<any>(tempObj);
  const [configuredTheme, setConfiguredTheme] = useState<any | null>(null);
  
  const consentService = new ConsentService(config!);

  useTitle("Manage Consent Banner");

  useEffect(() => {
    if (props.currentConsentData) {
    }
  }, [props.currentConsentData]);

  useEffect(() => {
      if(domainId) {
        getConsentById(domainId);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  const getConsentById = async (id:any) => {
    try {
      const response = await consentService.getConsentById(id);
      if (response?.data) {
        setConsentConfigRequest(response.data);
        let themeData = response.data?.theme?.theme ? { ...response.data } : { theme: { theme: themesConfig[0] } };
        setConfiguredTheme(themeData);
        localStorage.setItem("activeDomain", response.data.domainUrl);
      }
    } catch (error) {
      console.error("Error fetching consent data:", error);
    }
  };

  const handleConsentData = (fieldValue:any) => {
        const payload : any = {};

        payload.consentExpire = consentConfigRequest.consentExpire;
        payload.geoLocations = ['Asia-Pacific'];
        payload.domainUrl = consentConfigRequest.domainUrl;
        payload.language = consentConfigRequest.language;
        payload.subdomainConsentSharing = true;
        payload.consentLog = true;
        payload.styles = fieldValue;

        const response = consentService.updateConsent(domainId, payload);
        response.then((res) => {
           if(res) {
                setDomainId(res?.id);
                setDomainName(res?.domainUrl);
                showNotification({ color: "green", message: "Update successfully!" });
           }
        })
  };

  return (
    <React.Fragment>
      <>
        <Container fluid>
            <div>
              
              {domainId && configuredTheme && (
                <BannerConfiguration consentData={handleConsentData} consentConfigData={configuredTheme} domainId={domainId} domainName={domainName}/>
              )}
            </div>
        </Container>
      </>
    </React.Fragment>
  );
}

export { ConsentBannerPage };
