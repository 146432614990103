import { useEffect, useState } from 'react';
import { Card } from '@mantine/core';
import { LineChart } from '@mantine/charts';

const AnalyticsChart = (props:any) => {
  const [chartData, setChartData] = useState<any>(null);
  const [ticksConsents, setTicksConsents] = useState<number[]>([]);
  const dateTickFormatter = new Intl.DateTimeFormat(undefined, {day: '2-digit', month: 'short'});

  useEffect(() => {
    if(props.chartData) {
        const formattedChartData = formatChartData();
        setChartData(formattedChartData);

        const maxValue = Math.max(
          ...formattedChartData.flatMap((d: any) => [d.OptIns, d.OptOuts])
        );
        const x = Math.ceil(maxValue / 4);
        setTicksConsents([0, x, 2 * x, 3 * x, 4 * x]);
    }
  }, [props.chartData]);

  const formatChartData = () => {
    return props.chartData.map((item:any) => ({
      date: new Date(item.date).toDateString(),
      OptIns: item.optIns,
      OptOuts: item.optOuts,
    }));
  };

  return (
    <Card withBorder shadow="sm">
          {chartData && chartData.length > 0 ? (
             <LineChart
                h={300}
                data={chartData}
                dataKey="date"
                withLegend
                series={[
                    { name: 'OptIns', label: 'Opt-ins', color: "var(--mantine-primary-color-filled)" },
                    { name: 'OptOuts', label: 'Opt-outs', color: 'orange' },
                ]}
                curveType="linear"
                activeDotProps={{ r: 6, strokeWidth: 1, fill: '#fff' }}
                xAxisProps={{
                  tickFormatter: (date: string) => {
                    return dateTickFormatter.format(new Date(date));
                  }
                }}
                yAxisProps={{
                  ticks: ticksConsents,
                }}
                tooltipAnimationDuration={200}
            />
          ) : (
            <p>No data available for the selected period.</p>
          )}
        </Card>
  );
};

export default AnalyticsChart;
